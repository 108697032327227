import { mapValues, merge } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '../components/common/Button';
import { IconButton } from '../components/common/IconButton';
import { DeleteIcon } from '../components/common/Icons';
import { Loading } from '../components/common/Loading';
import { Page } from '../components/common/Page';
import { Row } from '../components/common/Row';
import { Stack } from '../components/common/Stack';
import { DataGrid } from '../components/DataGrid';
import { Dropdown } from '../components/form/Dropdown';
import { Form } from '../components/form/Form';
import { Input } from '../components/form/Input';
import { InputField } from '../components/form/InputField';
import { LiftHeader } from '../components/lift/LiftHeader';
import { LinkBox } from '../components/lift/LinkBox';
import { useApiMutation, useApiQuery } from '../hooks/useApi';
import { useDateFormat } from '../hooks/useDateFormat';
import { useFormSubmission } from '../hooks/useFormSubmission';
import { useTranslation } from '../I18nProvider';
import { color, fontSize } from '../tokens';
import { FullLift, LiftArgs } from '../utils/appApi';
import { getTodayDate } from '../utils/dateUtils';

const InputContainer = styled.div`
  background-color: #f5f5f5;
  padding: 0.5rem;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-wrap: column;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`;

const CheckboxHeadline = styled.div`
  color: green;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: ${fontSize.base};
  color: ${color.primary};
`;

const Hint = styled.div`
  font-size: ${fontSize.xs};
  padding-top: 0.5rem;
  color: ${color.cardText};
`;

export interface CheckboxOption {
  value: number;
  label: number;
  checked?: boolean;
}

interface SelectedDoorModel {
  value: string;
  type: string;
}

interface SelectedDoorType {
  value: string;
  type: string;
}

export function EditLift() {
  const params = useParams();
  const id = parseInt(params.id!);
  const query = useApiQuery('getLift', id);
  const doorScopeQuery = useApiQuery('getDoorScopes');
  const productQuery = useApiQuery('getProductStatus', id);
  const deleteLift = useApiMutation('deleteLift');
  const navigate = useNavigate();
  const t = useTranslation();
  const todayDate = getTodayDate();

  const {
    name,
    assetNumber,
    doorSerialNumber,
    box_id,
    box,
    floors,
    groundFloor,
    elevatorInstallationDate,
    carDoorCounter,
    doorType,
    doorModel,
    lastTimeDoorElectronicsChange,
    lastMaintenanceDate,
    lastMaintenanceScope,
    doorInstallationDate,
  } = query.data || {};

  const [lastMaintenanceScopeOptions, setLastMaintenanceScopeOptions] =
    useState<CheckboxOption[]>([]);
  const [selectedDoorModel, setSelectedDoorModel] = useState<SelectedDoorModel>(
    { value: t.selectDoorModel, type: 'default' }
  );
  const [selectedDoorType, setSelectedDoorType] = useState<SelectedDoorType>({
    value: t.selectDoorType,
    type: 'default',
  });
  const [selectedDoorInstallationDate, setSelectedDoorInstallationDate] =
    useState(null);
  const [
    selectedLastTimeDoorElectronicsChange,
    setSelectedLastTimeDoorElectronicsChange,
  ] = useState(null);
  const [selectedCarDoorCounter, setSelectedCarDoorCounter] = useState(null);

  // useEffect(() => {
  //   if (!query.isSuccess) return;
  //   const updatedOptions = lastMaintenanceScopeOptions.map((option) => ({
  //     ...option,
  //     checked: false,
  //   }));
  //   setLastMaintenanceScopeOptions(updatedOptions);
  // }, [selectedDoorModel.value]);

  useEffect(() => {
    if (
      query.isSuccess &&
      doorScopeQuery.isSuccess &&
      query.data?.doorModel &&
      selectedDoorModel.value === query.data.doorModel
    ) {
      const doorModelData = doorScopeQuery.data.find(
        (type) => type.doorModel === query.data.doorModel
      );
      if (doorModelData) {
        const options = doorModelData.lastMaintenanceScope.map((scope) => ({
          value: scope,
          label: scope,
          checked: !!query.data.lastMaintenanceScope.includes(scope) || false,
        }));
        setLastMaintenanceScopeOptions(options);
      }
    }
  }, [
    query.isSuccess,
    query.data?.doorModel,
    doorScopeQuery.isSuccess,
    doorScopeQuery.data,
    selectedDoorModel.value,
  ]);

  useEffect(() => {
    if (
      query.isSuccess &&
      query.data.doorModel &&
      selectedDoorModel.value === t.selectDoorModel
    ) {
      setSelectedDoorModel({
        value: query.data.doorModel,
        type: query.data.doorModel,
      });
    }
  }, [query]);

  const selectDoorModel = (doorModel: string) => {
    setSelectedDoorModel({ value: doorModel, type: doorModel });

    if (doorScopeQuery.data) {
      const doorModelData = doorScopeQuery.data.find(
        (type) => type.doorModel === doorModel
      );
      if (doorModelData) {
        const options = doorModelData.lastMaintenanceScope.map((scope) => ({
          value: scope,
          label: scope,
        }));
        setLastMaintenanceScopeOptions(options);
        lastMaintenanceScopeOptions.forEach((option) => {
          option.checked = false;
        });
      }
    }
  };

  const selectDoorType = (doorType: string) => {
    setSelectedDoorType({ value: doorType, type: doorType });
  };

  const updateLift = useApiMutation('updateLift', {
    onSuccess: (_result, [id, data]) => {
      query.update((old: FullLift | undefined) => merge({}, old, data));
    },
  });
  const updateBoxStaticData = useApiMutation('updateBoxStaticData', {
    onSuccess: () => {
      query.refetch();
    },
  });

  const static_data: any = box?.static_data ?? {};
  const { driveesn, drivesoftware, ipaddress, doorwidth, couplerwidth } =
    static_data;

  const dateFormat = useDateFormat();
  const { formatDate } = dateFormat;
  const summary = {
    box_id,
    assetNumber,
    doorSerialNumber,
    floors,
    groundFloor: groundFloor ?? null,
    elevatorInstallationDate: elevatorInstallationDate
      ? formatDate(elevatorInstallationDate)
      : null,
    carDoorCounter: carDoorCounter ?? null,
    doorModel: doorModel ?? null,
    doorType: doorType ?? null,
    lastTimeDoorElectronicsChange: lastTimeDoorElectronicsChange
      ? formatDate(lastTimeDoorElectronicsChange)
      : null,
    lastMaintenanceDate: lastMaintenanceDate
      ? formatDate(lastMaintenanceDate)
      : null,
    lastMaintenanceScope:
      lastMaintenanceScope && lastMaintenanceScope.length > 0
        ? `${lastMaintenanceScope} ${t.years}`
        : null,
    doorInstallationDate: doorInstallationDate
      ? formatDate(doorInstallationDate)
      : null,
    driveesn,
    drivesoftware,
    ipaddress,
    doorwidth,
    couplerwidth,
  };

  const isDoorTypeAvailable = !!doorScopeQuery.data?.find(
    (el) => el.doorModel === selectedDoorModel.value
  )?.doorTypes;

  const [
    lastTimeDoorElectronicsChangeError,
    setLastTimeDoorElectronicsChangeError,
  ] = useState<boolean>(false);
  const [doorInstallationDateError, setDoorInstallationDateError] =
    useState<boolean>(false);
  const [elevatorInstallationDateError, setElevatorInstallationDateError] =
    useState<boolean>(false);

  const { form, register } = useFormSubmission<LiftArgs>({
    async onSubmit(data: LiftArgs) {
      const cleanedData = mapValues(data, (value: any) => {
        return Number.isNaN(value) ? null : value === '' ? null : value;
      });

      cleanedData.lastMaintenanceScope = lastMaintenanceScopeOptions
        .filter((option) => option.checked)
        .map((option) => option.value);

      cleanedData.box_id = box_id;
      cleanedData.doorInstallationDate = selectedDoorInstallationDate || null;

      if (
        selectedDoorModel.type !== 'default' &&
        (!isDoorTypeAvailable ||
          (isDoorTypeAvailable && selectedDoorType.type !== 'default'))
      ) {
        cleanedData.doorModel =
          selectedDoorModel.type !== 'default' ? selectedDoorModel.value : null;
        cleanedData.doorType =
          selectedDoorType.type !== 'default' ? selectedDoorType.value : null;
      }

      const lastTimeDoorElectronicsChangeSelected =
        cleanedData.lastTimeDoorElectronicsChange ||
        lastTimeDoorElectronicsChange;
      const doorInstallationDateSelected =
        cleanedData.doorInstallationDate || doorInstallationDate;
      const elevatorInstallationDateSelected =
        cleanedData.elevatorInstallationDate || elevatorInstallationDate;

      if (
        lastTimeDoorElectronicsChangeSelected < doorInstallationDateSelected
      ) {
        if (
          lastTimeDoorElectronicsChangeSelected &&
          doorInstallationDateSelected
        ) {
          setLastTimeDoorElectronicsChangeError(true);
          setDoorInstallationDateError(true);
          return;
        }
      }

      if (
        lastTimeDoorElectronicsChangeSelected < elevatorInstallationDateSelected
      ) {
        if (
          lastTimeDoorElectronicsChangeSelected &&
          elevatorInstallationDateSelected
        ) {
          setLastTimeDoorElectronicsChangeError(true);
          setElevatorInstallationDateError(true);
          return;
        }
      }

      if (doorInstallationDateSelected < elevatorInstallationDateSelected) {
        if (doorInstallationDateSelected && elevatorInstallationDateSelected) {
          setDoorInstallationDateError(true);
          setElevatorInstallationDateError(true);
          return;
        }
      }

      await updateLift(id, cleanedData);
      navigate('../');
    },
  });

  const detachBox = async () => {
    await updateLift(id, { box_id: null });
  };

  const getDefaultValue = (value: any) => {
    return value ? value : undefined;
  };

  const getDefaultDateValue = (dateString: any) => {
    if (typeof dateString !== 'string') {
      return undefined;
    }
    return formatDate(dateString);
  };

  const onCheckboxChange = (event: any, value: number) => {
    const options = lastMaintenanceScopeOptions?.map((scope) => {
      if (scope.value == value) {
        scope.checked = !scope.checked;
      }
      return scope;
    });
    setLastMaintenanceScopeOptions(options);
  };

  const deleteButton = (
    <IconButton
      disabled={deleteLift.isLoading}
      title={t.delete}
      onClick={async () => {
        if (confirm(t.confirmDeleteLift)) {
          await deleteLift(id);
          navigate('../../../');
        }
      }}
    >
      <DeleteIcon />
    </IconButton>
  );
  return (
    <Page
      header={<LiftHeader id={id} action={deleteButton} />}
      pageTitle={t.editLift}
      backLink="../"
    >
      {query.isLoading && <Loading />}
      {query.isSuccess && (
        <Form {...form}>
          <Stack>
            <InputField
              register={register}
              name="name"
              placeholder={t.name}
              defaultValue={name}
              required
            />
            <InputField
              register={register}
              name="assetNumber"
              placeholder={t.assetNumber}
              defaultValue={assetNumber ?? ''}
            >
              {t.assetNumberHint}
            </InputField>
            <InputField
              register={register}
              name="doorSerialNumber"
              placeholder={t.doorSerialNumber}
              defaultValue={doorSerialNumber ?? ''}
            ></InputField>
            {box_id ? (
              <>
                <Row>
                  <Input
                    style={{ flexGrow: 1 }}
                    placeholder={t.box_id}
                    value={box_id || ''}
                    readOnly
                  />
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => detachBox()}
                  >
                    {t.unlinkBox}
                  </Button>
                </Row>
                <DataGrid
                  data={summary}
                  showButton={true}
                  onButtonClick={() => updateBoxStaticData(box_id)}
                />
              </>
            ) : (
              <LinkBox onLink={(newData) => updateLift(id, newData)} />
            )}
            {!floors && (
              <InputField
                required
                register={register}
                name="floors"
                type="number"
                placeholder={t.floors}
                options={{ valueAsNumber: true, min: 1 }}
                defaultValue={floors}
              />
            )}
            {!groundFloor && groundFloor !== 0 && (
              <InputField
                register={register}
                name="groundFloor"
                type="number"
                placeholder={t.groundFloor}
                options={{ valueAsNumber: true, min: 0 }}
                defaultValue={getDefaultValue(groundFloor)}
              />
            )}
            {!doorModel && (
              <Dropdown
                register={register}
                name="doorModel"
                label={t.doorModel}
                value={selectedDoorModel.value}
                onChange={(e) => {
                  selectDoorModel(e.target.value);
                }}
                labelColor="primary"
              >
                {selectedDoorModel && (
                  <option value={selectedDoorModel.value}>
                    {selectedDoorModel.value}
                  </option>
                )}
                {doorScopeQuery.data?.map((doorScope) => {
                  if (doorScope.doorModel === selectedDoorModel.value)
                    return null;
                  return (
                    <option value={doorScope.doorModel} key={doorScope.id}>
                      {doorScope.doorModel}
                    </option>
                  );
                })}
              </Dropdown>
            )}
            {!doorType && isDoorTypeAvailable && (
              <Dropdown
                register={register}
                name="doorType"
                label={t.doorType}
                value={selectedDoorType.value}
                onChange={(e) => {
                  selectDoorType(e.target.value);
                }}
                labelColor="primary"
              >
                {selectedDoorType && (
                  <option value={selectedDoorType.value}>
                    {selectedDoorType.value}
                  </option>
                )}
                {doorScopeQuery.data
                  ?.find((el) => el.doorModel === selectedDoorModel.value)
                  ?.doorTypes?.map((doorType: string) => (
                    <option value={doorType} key={doorType}>
                      {doorType}
                    </option>
                  ))}
              </Dropdown>
            )}
            {!elevatorInstallationDate && (
              <InputField
                register={register}
                name="elevatorInstallationDate"
                type="date"
                max={todayDate}
                placeholder={t.elevatorInstallationDate}
                defaultValue={getDefaultDateValue(elevatorInstallationDate)}
                onInput={(e: any) => {
                  setElevatorInstallationDateError(false);
                  if (!doorInstallationDate) {
                    setSelectedDoorInstallationDate(e.target.value);
                  }
                }}
                hasError={elevatorInstallationDateError}
                errorMessage={
                  elevatorInstallationDateError
                    ? t.installationDateErrorMessage
                    : ''
                }
              />
            )}
            {!doorInstallationDate && (
              <InputField
                register={register}
                name="doorInstallationDate"
                type="date"
                max={todayDate}
                placeholder={t.doorInstallationDate}
                defaultValue={
                  selectedDoorInstallationDate ||
                  getDefaultDateValue(doorInstallationDate)
                }
                onInput={(e: any) => {
                  setDoorInstallationDateError(false);
                  setSelectedDoorInstallationDate(e.target.value);
                }}
                hasError={doorInstallationDateError}
                errorMessage={
                  doorInstallationDateError
                    ? t.installationDateErrorMessage
                    : ''
                }
              />
            )}
            {/* {!lastMaintenanceDate && (
              <InputField
                register={register}
                name="lastMaintenanceDate"
                type="date"
                placeholder={t.lastMaintenanceDate}
                defaultValue={getDefaultDateValue(lastMaintenanceDate)}
              />
            )} */}
            {/* {selectedDoorModel.type !== 'default' &&
              lastMaintenanceScope &&
              (!doorModel || selectedDoorModel) && (
                <InputContainer>
                  <CheckboxHeadline>{t.lastMaintenanceScope}</CheckboxHeadline>
                  <CheckboxContainer>
                    {lastMaintenanceScopeOptions?.map((option: any) => (
                      <CheckboxLabel key={option.value}>
                        <ToggleButton
                          onChange={(e) => {
                            onCheckboxChange(e, option.value);
                          }}
                          value={option.checked}
                        >
                          {option.label} {t.years}
                        </ToggleButton>
                      </CheckboxLabel>
                    ))}
                  </CheckboxContainer>
                  <Hint>{t.lastMaintenanceScopeHint}</Hint>
                </InputContainer>
              )} */}
            {!lastTimeDoorElectronicsChange && (
              <InputField
                register={register}
                name="lastTimeDoorElectronicsChange"
                type="date"
                max={todayDate}
                placeholder={t.lastTimeDoorElectronicsChange}
                defaultValue={getDefaultDateValue(
                  lastTimeDoorElectronicsChange
                )}
                onInput={(e: any) => {
                  setLastTimeDoorElectronicsChangeError(false);
                  setSelectedLastTimeDoorElectronicsChange(e.target.value);
                }}
                required={!!selectedCarDoorCounter}
                hasError={lastTimeDoorElectronicsChangeError}
                errorMessage={
                  lastTimeDoorElectronicsChangeError
                    ? t.errorEarlierThanInstallationDate
                    : ''
                }
              />
            )}
            {!carDoorCounter &&
              (lastTimeDoorElectronicsChange ||
                selectedLastTimeDoorElectronicsChange) && (
                <InputField
                  register={register}
                  name="carDoorCounter"
                  type="number"
                  placeholder={t.carDoorCounter}
                  options={{ valueAsNumber: true, min: 0 }}
                  defaultValue={getDefaultValue(carDoorCounter)}
                  required={!lastTimeDoorElectronicsChange}
                  onInput={(e: any) => {
                    setSelectedCarDoorCounter(e.target.value);
                  }}
                />
              )}
            <Row gap=".5rem">
              <Button as={Link} to="../" variant="secondary">
                {t.cancel}
              </Button>
              <Button variant="primary">{t.continue}</Button>
            </Row>
          </Stack>
        </Form>
      )}
    </Page>
  );
}
