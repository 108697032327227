import React from 'react';

import { Translations } from './en';

export const fr: Translations = {
  aborted: 'Annulé',
  accelerationDescription:
    'Facteur d’accélération utilisé pour le calcul de la courbe.',
  accelerationNote:
    'Ce facteur doit être suffisamment élevé pour atteindre les vitesses réglées, sinon la porte n’atteindra pas la vitesse souhaitée.',
  accelerationTitle: 'Accélération',
  acceptTerms: 'J’accepte les conditions d’utilisation',
  add: 'Ajouter',
  addBuilding: 'Ajouter un bâtiment',
  addLift: 'Ajouter un ascenseur',
  addressHint:
    'Saisissez le nom d’une entreprise ou d’un bâtiment pour obtenir des suggestions d’adresses et d’images.',
  adjustCameraSettings:
    'Vous pouvez également ajuster les paramètres de la caméra dans le menu déroulant.',
  admin: 'Admin',
  alreadyHasSubcontractorsError:
    "En tant que sous-traitant, vous ne pouvez pas avoir de sous-traitants. Veuillez supprimer les sous-traitants existants avant d'accepter cette invitation.",
  all: 'Tous',
  allBuildings: 'Tous les bâtiments',
  allBuildingsSubscription: 'De tous les bâtiments',
  alphabetical: 'Alphabétique',
  ambientTemperature: 'Température de la boîte',
  assetNumber: 'ID d’ascenseur',
  assetNumberHint:
    'Veuillez saisir l’ID (c’est-à-dire le numéro d’installation/d’équipement) de l’ascenseur si vous l’avez sous la main.',
  auto: 'Automatique',
  back: 'Retour',
  backToDashboard: 'Retour au tableau de bord',
  belt_tension: 'Tension de la courroie',
  belt_tension_actions:
    'Réglez la tension de la courroie comme décrit dans le manuel d’installation.',
  beltclamp: 'Pince à courroie',
  belt_tension_description: 'La tension de la courroie est trop élevée/faible.',
  betweenDoorCycle: 'Entre le cycle de la porte',
  bottom: 'bas',
  bottomToTop: 'De bas en haut',
  boxLinkedToOtherLift: (lift: string, building: string) =>
    `La boîte est liée à ${lift} dans ${building}. Continuer?`,
  boxNotReady: 'La boîte sélectionnée n’est pas prête.',
  boxOfflineHint: `Si vous souhaitez mettre à jour l'un des boîtiers énumérés ci-dessous veuillez d'abord le connecter à l'internet.`,
  boxScannedNoIdFound: 'Boîte scannée, mais aucun ID trouvé.',
  boxSoftware: 'Logiciel',
  boxSoftwareStatusError:
    "Le statut des boîtes n'a pas pu être récupéré : veuillez vérifier la connexion Internet de la boîte et réessayez. Si le problème persiste, contactez votre représentant Wittur.",
  boxSoftwareTitle: 'Logiciel de Boîte',
  boxSoftwareUpdateError:
    'Échec de la mise à jour du logiciel de la boîte : veuillez vérifier la connexion Internet de la boîte et réessayez. Si le problème persiste, contactez votre représentant Wittur.',
  box_id: 'ID de boîte',
  brakeParDescription:
    'En raison du réglage de ce paramètre, le retard et la dérive vers la fin d’ouverture/fermeture sont influencés (préréglé sur « 5 »).',
  brakeParNote:
    'La plage de réglage possible est « 0 »... « 9 », mais le premier frein est atteint avec le paramètre « 0 » et le dernier retard est atteint avec le paramètre « 9 ».',
  brakeParTitle: 'Paramètre de freinage',
  buffer: 'Tampon',
  building: 'Bâtiment',
  buildingHasNoElevator: "Ce bâtiment n'a pas d'ascenseurs.",
  buildingName: 'Nom',
  buildings: 'Bâtiments',
  buildingsWithoutValidAddress: 'Bâtiments sans adresse valide',
  bushingClose: 'Manchon fermé',
  bushingOpen: 'Manchon ouvert',
  cablechain: 'Chaîne de câble',
  cableTensorSpring: 'Ressort de tension du câble',
  callFloorsInstruction: (
    <>
      {' '}
      Ouvrez la porte et <b>appelez tous les autres étages</b>. Sortez de la
      cabine et cliquez sur «Continuer».
    </>
  ),
  cameraMode: 'Mode caméra',
  cancel: 'Annuler',
  car: 'Cabine',
  carDoor: 'Porte de la cabine',
  carDoorCounter:
    "Compteur de cycles de porte de l'électronique de porte précédente",
  carDoorModified: 'La porte de la cabine a été modifiée',
  cd_counter_rollers_installation: 'Contre-rouleaux',
  cd_counter_rollers_installation_actions:
    '`Pour chaque porte palière, vérifiez si les galets peuvent être tournés facilement à la main et si leur distance au rail n’est pas trop grande. Si tel est le cas, desserrez les galets et réajustez-les afin qu’ils soient le plus près possible du rail, mais sans le toucher, et qu’ils puissent toujours être tournés facilement à la main. Si vous devez démonter le coupleur pour accéder aux galets, suivez les instructions de montage.`',
  cd_counter_rollers_installation_description:
    'Les contre-rouleaux CD ne sont pas installés correctement.',
  cd_roller: 'Galet CD',
  chooseFloorOrCardoor: 'Choisissez Étage / Cardoor',
  choosePartNumber: 'Choisissez le numéro de pièce',
  chooseReason: 'Choisissez une raison (facultatif)',
  chooseStartingFloorInstructions:
    'Veuillez sélectionner l’étage de départ de la course de contrôle. L’étage de départ peut être soit l’étage supérieur, soit l’étage inférieur.',
  clearDriveErrors: 'Effacer',
  close: 'Fermer',
  closeDoorInstructions: (floor: string) => (
    <>
      {' '}
      Dirigez-vous vers l’étage <b>{floor}</b>. Assurez-vous que la porte de la
      cabine est fermée avant de continuer.
    </>
  ),
  closeForcePotiDescription:
    "Définir la limite de force utilisée pour l'ouverture et la fermeture de la porte.",
  closeForcePotiNote:
    "Réglable par potentiomètre sur l'électronique. Sur les nouveaux systèmes électroniques comme le MIDIV2, le réglage peut se faire ici en mode de conduite de service si nécessaire (il n'est pas nécessaire de le régler dans les applications standard en raison de l'auto-apprentissage).",
  closeForcePotiTitle: 'Limite de la force de fermeture',
  closeHoldForceDescription: 'Force appliquée en fin de fermeture.',
  closeHoldForceNote:
    'Cette force doit être comprise entre 50 N et 70 N. Sachez qu’une force trop faible entraînera des ouvertures de coupleur indésirables. Une force trop élevée chauffera inutilement le moteur et pourra le faire fonctionner en mode de réduction.',
  closeHoldForceTitle: 'Force de fin de fermeture (maintien)',
  closeSpeedDescription:
    'Vitesse maximale de la porte dans le sens de fermeture. La courbe de fermeture est calculée pour atteindre cette vitesse réglée si possible.',
  closeSpeedNote:
    'La vitesse réelle de la porte est également liée aux commutateurs DIP de vitesse des entraînements. Le réglage sur la vitesse 4 signifie 100 % de la valeur 2.',
  closeSpeedTitle: 'Vitesse de fermeture',
  closing_device_failure: 'Dispositif de fermeture',
  closing_device_failure_actions:
    '`Vérifiez si tous les composants du dispositif de fermeture de la porte palière fonctionnent correctement (Spirator, ressort de fermeture et poids de fermeture). La porte palière doit pouvoir se fermer d’elle-même à partir de n’importe quelle position de porte.`',
  closing_device_failure_description:
    'Le dispositif de fermeture n’est pas installé correctement. La force nécessaire pour fermer la porte est trop grande.',
  companyName: "Nom de l'entreprise",
  completed: 'Terminé',
  componentDetails: 'Détails du composant',
  componentDetailsHint:
    "Pour certains composants, plusieurs options de numéro de pièce doivent être sélectionnées. Il s'agit d'informations importantes pour l'utilisation de la fonction de maintenance préventive. Veuillez cliquer sur les éléments répertoriés pour sélectionner la pièce réellement installée.",
  confirm: 'Confirmer',
  confirmDeleteBuilding: 'Voulez-vous vraiment supprimer ce bâtiment ?',
  confirmDeleteErrorHistory:
    "Voulez-vous vraiment supprimer cet historique d'erreurs?",
  confirmDeleteFavoriteBuilding:
    'Voulez-vous vraiment supprimer ce bâtiment de vos favoris ?',
  confirmDeleteLift: 'Voulez-vous vraiment supprimer cet ascenseur ?',
  confirmInvite: 'Confirmer et Connecter',
  connectionErrorMessage:
    "Impossible de récupérer les données en raison d'un problème de connexion. Veuillez réessayer plus tard.",
  continue: 'Continuer',
  contractorBuilding: 'Bâtiment du contractant',
  contractorBuildings: 'Bâtiments du contractant',
  contractors: 'Contractants',
  couplerelease: 'Déblocage du coupleur',
  couplerFriction: 'Friction du coupleur',
  couplerMovementParameter: 'Paramètre de mouvement du sabre',
  couplerSpeedDescription:
    'Cette valeur définit la vitesse maximale de la courroie, dans la zone définie du coupleur.',
  couplerSpeedTitle: 'Vitesse du coupleur',
  couplerWitdhDescription:
    'Cette valeur définit la distance de la courroie dans la zone du coupleur. Cette valeur résulte de la procédure d’apprentissage.',
  couplerWitdhTitle: 'Largeur du coupleur',
  currentVersion: 'Version actuelle',
  couplerwidth: 'Largeur du coupleur',
  date: 'Date',
  delete: 'Supprimer',
  deleteErrorHistory: "Supprimer l'historique des erreurs",
  directConnection: (
    <>
      <p>
        {' '}
        Connectez-vous au WIFI de la boîte ELEVATORSENSE en scannant le code QR
        imprimé sur la boîte avec l’application appareil photo de votre
        téléphone.
      </p>
      <p>
        {' '}
        Une fois connecté, revenez à cet écran et appuyez sur le bouton{' '}
        <b>Continuer</b> ci-dessous.
      </p>
    </>
  ),
  disconnectProgrammingTool: 'Déconnecter la fonction "Wireless Programming',
  divpulley: 'Divpulley',
  documentation: 'Documentation',
  doorContact: 'Contact de porte',
  doorCycleCounter: 'Compteur de cycles de porte',
  doorCycleError1: 'Aucune donnée de cycle de porte n’a été reçue.',
  doorCycleError2: 'Le mouvement du cycle de la porte a été perturbé.',
  doorCycleError4: 'Une réouverture de la porte a été détectée.',
  doorCycleError5: 'Une refermeture de la porte a été détectée.',
  doorCycleError7: 'Aucun cycle de porte valide n’a été reçu.',
  doorCycles: 'Cycles de la porte',
  doorFriction: 'Friction de la porte',
  doorInstallationDate: "Date d'installation de la porte",
  doorIsClosed: 'Oui, la porte est fermée',
  doorModel: 'Modèle de porte',
  doorModelNotSupported:
    "Le modèle de porte sélectionné n'est pas disponible pour la maintenance préventive.",
  doorMovementParameter: 'Paramètre de mouvement de la porte',
  doorPosition: 'Position de la porte',
  doorSerialNumber: 'Numéro de série de la porte',
  doorType: 'Type de porte',
  doorwidth: 'Largeur d’ouverture de la porte',
  downloadCertificate: 'Télécharger le certificat',
  downloadLicense: 'Télécharger toutes les licences au format CSV',
  downloadRequestedExtendedWarranty: `Télécharger toutes les demandes d'extension de garantie`,
  drive_motor_noise: 'Bruit du moteur d’entraînement',
  driveesn: 'ESN',
  drivesoftware: 'Micrologiciel',
  duringLiftMovement: "Pendant le déplacement de l'ascenseur",
  editBuilding: 'Modifier un bâtiment',
  editLift: 'Modifier un ascenseur',
  elecBoard: 'Carte électronique',
  elevatorIdNotFound: (
    <>
      Pour bénéficier d'une extension de garantie, la série de portes doit être
      introduite dans les paramètres de l'ascenseur.
      <b>Cliquez ici pour accéder aux réglages.</b>
    </>
  ),
  elevatorInstallationDate: "Date d'installation de l'ascenseur",
  elevatorUsageType: "Type d'utilisation de l'ascenseur",
  email: 'Courriel',
  encoderTemperature: 'Température du capteur rotatif',
  error: 'Erreur',
  errorDescription: 'Description de l’erreur',
  errorEarlierThanInstallationDate:
    "Il n'est pas possible d'entrer une date antérieure à la date d'installation.",
  errorInvalid: 'Veuillez saisir une valeur valide.',
  errorInvalidOperation: 'Opération invalide',
  errorMessages: 'Messages d’erreur',
  errorMessagesDescription: 'Affichage des messages d’erreur enregistrés.',
  errorNotFound: 'Ressource introuvable',
  errorRetryCamera:
    "Impossible d'accéder à la caméra. Nouvelle tentative, la page va se recharger...",
  errorRequired: 'Ce champ est obligatoire.',
  errorUnknownOrForbiddenBox: `La boîte n'est pas disponible. Veuillez contacter votre service Wittur si vous avez besoin d'aide.`,
  errorUnableToAccessCamera:
    "Impossible d'accéder à la caméra après plusieurs tentatives. Veuillez actualiser la page ou vérifier les autorisations de la caméra.",
  errors: 'Erreurs',
  errorsEmpty: 'Pas de messages.',
  errorsEmptyHint:
    'Veuillez vérifier vos filtres et éventuellement activer des types supplémentaires.',
  extendWarranty: 'Extension de la garantie',
  fatalError: 'Erreur fatale',
  fatalErrors: 'Erreurs fatales',
  favorite: 'Favori',
  favorites: 'Favoris',
  figureAdjustment: 'Réglage',
  figureAugustaEVO: 'Exemple Augusta EVO',
  figureClosingWeight: 'Poids de fermeture',
  figureEco: 'ECO+',
  figureHydra: 'Exemple Hydra',
  figureMidi: 'MIDI/SUPRAV2',
  figureNotOk: 'Pas OK',
  figureOk: 'OK',
  figureSGV: 'Exemple SGV',
  figureSpirator: 'Spirator',
  figureSpring: 'Ressort de fermeture',
  firstName: 'Prénom',
  floor: 'Étage',
  floors: 'Étages',
  followingBoxScanned: 'La boîte suivante a été scannée',
  forgotPassword: 'Mot de passe oublié ?',
  forgotPasswordText:
    'Saisissez votre adresse électronique afin de réinitialiser votre mot de passe.',
  groundFloor: 'Rez-de-chaussée',
  home: 'Accueil',
  incompleteElevatorSettings:
    "Afin d'utiliser la maintenance préventive, nous avons besoin de plus d'informations sur cet ascenseur. Veuillez vous rendre dans les paramètres de votre ascenseur et remplir les informations manquantes.",
  initialized: 'Initialisé',
  installationControl: 'Contrôle d’installation',
  installationControlDescription:
    'Effectuez des vérifications automatiques pour détecter les problèmes d’installation courants.',
  installationControlExecutedBy: 'Exécuté par',
  installationControlHeadline: 'Dernier état d’installation',
  installationControlHistory: 'Historique des courses',
  installationControlHistoryEmpty: 'Aucune course d’installation enregistrée',
  installationControlInstructions: 'Afficher les instructions',
  installationControlSubheadline: 'Résumé de toutes les courses',
  installationDateErrorMessage:
    "Il est impossible de saisir une date d'installation de porte antérieure à la date d'installation de l'ascenseur",
  instantSubscription: 'Notification instantanée',
  instantSubscriptionExplanation: (
    <>
      La <b>notification instantanée</b> est une notification immédiate par
      courriel dès qu’une erreur fatale s’est produite sur un ascenseur. Les
      erreurs fatales sont des erreurs qui peuvent entraîner le
      dysfonctionnement immédiat de l’ascenseur.
    </>
  ),
  invitationExpired: 'Le lien d’invitation n’est plus valide.',
  inviteAsManager: 'L’utilisateur peut inviter d’autres utilisateurs.',
  inviteCompanyWithSubcontractorsError:
    "L'entreprise que vous essayez d'inviter a déjà des sous-traitants et ne peut pas être ajoutée en tant que sous-traitant. Ils doivent supprimer les sous-traitants existants ou choisir une autre entreprise.",
  inviteCoworkers: 'Inviter des collègues',
  inviteSubcontractor: 'Inviter un sous-traitant',
  inviteToSignUpMessage: (companyName: string) =>
    `L'entreprise ${companyName} vous a invité à vous inscrire sur ElevatorSense en tant que sous-traitant. Veuillez remplir le formulaire pour vous inscrire.`,
  inviteToBeSubcontractorMessage: (companyName: string) =>
    `${companyName} vous a invité à être leur sous-traitant dans ElevatorSense.`,
  ipaddress: 'Adresse IP',
  joinText: (name: string) => (
    <>
      Veuillez vous inscrire pour rejoindre l’organisation <i>{name}</i>.
    </>
  ),
  landingDoor: 'Porte palière - Étage',
  landing_lock_roller_adjustment:
    'Réglage du rouleau de verrouillage de palier',
  landingCarDoorSpareParts: 'Pièces de rechange de porte palière/cabine',
  lastError: 'Dernière erreur',
  lastExchangeDate: "Dernière date d'échange",
  lastMaintenanceDate: 'Date de la dernière maintenance',
  lastMaintenanceScope: 'Portée de la dernière maintenance',
  lastMaintenanceScopeHint:
    "Sélectionnez l'étendue de la maintenance recommandée par Wittur qui a été effectivement effectuée à la dernière date de maintenance.",
  lastName: 'Nom de famille',
  lastTimeDoorElectronicsChange:
    "Date de remplacement de l'électronique de la porte",
  latestSoftwarePackVersion: (version: string) =>
    `Version du logiciel : ${version}`,
  ld_counter_rollers_installation: 'Contre-galets',
  ld_counter_rollers_installation_actions:
    '`Pour chaque porte palière, vérifiez si les galets peuvent être tournés facilement à la main et si leur distance au rail n’est pas trop grande. Si tel est le cas, desserrez les galets et réajustez-les afin qu’ils soient le plus près possible du rail, mais sans le toucher, et qu’ils puissent toujours être tournés facilement à la main. Si vous devez démonter le coupleur pour accéder aux galets, suivez les instructions de montage.`',
  ld_counter_rollers_installation_description:
    'Les contre-galets LD ne sont pas installés correctement.',
  ld_roller: 'Galet LD',
  left: 'Gauche',
  licenseRequired:
    'Vous n’avez pas acheté de licence valide pour ce produit. Veuillez contacter votre représentant commercial Wittur pour acheter ce produit ElevatorSense.',
  lift: 'Ascenseur',
  liftMovementDistance: 'Mouvement de l’ascenseur',
  liftSetup: 'Configuration de l’ascenseur',
  liftSetupComplete: 'Configuration terminée.',
  liftSetupError: 'Les données correspondantes sont incomplètes ou manquantes.',
  liftSetupError40:
    'Le mouvement de l’ascenseur ne correspond pas à la course de configuration.',
  liftSetupIncomplete: 'Configuration incomplète.',
  liftSetupPending:
    "Veuillez effectuer l'exécution de l'installation pour la configuration initiale d'ElevatorSense sur votre ascenseur. Sans l'exécution de la configuration, vous ne pourrez pas utiliser certaines fonctionnalités des produits ElevatorSense ou les résultats des produits ElevatorSense peuvent différer. Cliquez ici pour effectuer la configuration.",
  liftSetupRepeat:
    'La course de configuration n’a pas réussi. Veuillez recommencer l’analyse en tenant compte de la description du processus.',
  lifts: 'Ascenseurs',
  linkBox: 'Associer la boîte',
  linkToWebshop: (link: string) => (
    <>
      Si vous avez une boîte ElevatorSense et que vous souhaitez activer une
      licence, cliquez{' '}
      <a href={link} className="clickable-link">
        ici
      </a>
      .
    </>
  ),
  loading: 'Chargement',
  login: 'Connexion',
  loginText: 'Veuillez vous connecter avec votre compte.',
  logout: 'Déconnexion',
  mandatory: 'Effectuer le contrôle de remplacement',
  manageAccess: 'Gérer l’Accès',
  manageElevators: 'Gérer les ascenseurs',
  manageSharedBuildingsAndElevator: 'Gérer les bâtiments/ascenseurs partagés',
  manual: 'Manuel',
  maxElectronics: 'Ascenseurs max.',
  maxUsage: 'Utilisation max.',
  measured: 'Mesuré',
  members: 'Membres',
  misalignment_cd_vs_ld: 'Alignement porte/sabre',
  misalignment_cd_vs_ld_actions:
    'Réglez les galets de verrouillage de la porte palière. Le jeu de fonctionnement entre les aubes du sabre et les galets doit être le même à gauche et à droite.',
  misalignment_cd_vs_ld_description:
    'Le sabre de porte de cabine est mal aligné avec les galets de verrouillage de la porte palière.',
  misalignment_cd_vs_ld_sill_gap: 'Écart de seuil',
  misalignment_cd_vs_ld_sill_gap_actions:
    'L’écart du seuil à l’extrémité gauche et à l’extrémité droite de l’ouverture de la porte n’est pas égal. Si cela se produit sur tous les paliers, réglez la position du seuil de la porte de la cabine.',
  misalignment_cd_vs_ld_sill_gap_description:
    'La porte palière n’est pas parallèle à la porte de la cabine.',
  mode: 'Mode',
  monitoring: 'Surveillance',
  motor: 'Moteur',
  motorTemperature: 'Température du moteur',
  myBuildingsSubscription: 'De mes favoris uniquement',
  name: 'Nom',
  networkQuality: 'Qualité du réseau',
  newInstallationControl: 'Nouveau contrôle d’installation',
  next: 'Suivant',
  nextMaintenanceDate: 'Prochaine date de maintenance',
  no: 'Non',
  notAuthorizedToConfirmInviteError:
    "Vous n'êtes pas autorisé à confirmer cette invitation, car elle n'est pas destinée à votre utilisateur connecté.",
  noBoxLinked:
    'L’ascenseur n’est pas encore lié à une boîte ELEVATORSENSE. Afin d’utiliser toutes les fonctionnalités de l’application, veuillez scanner le code QR sur la boîte ou saisir manuellement l’identifiant de la boîte.',
  noBuildingsVisible:
    "Aucun bâtiment n'est visible dans la vue actuelle de la carte. Essayez d'ajuster la carte pour voir plus de bâtiments.",
  noComponentsFound: 'Aucun composant trouvé pour ce sol',
  noFavorite: 'Pas un favori',
  noFavoritesChoosen:
    'Vous n’avez pas encore sélectionné de bâtiments favoris. Naviguez jusqu’à votre bâtiment et cliquez sur l’icône en forme d’étoile pour l’ajouter à vos bâtiments favoris.',
  noFloorInfoAvailable: "Aucune information sur l'étage disponible",
  noInstallationControlFound:
    "L'ESN de l'électronique de porte ne correspond pas à l'ESN du dernier cycle de contrôle de l'installation. Veuillez vérifier si vous êtes connecté au bon ascenseur.",
  noSubcontractors: 'Aucun sous-traitant trouvé.',
  noSubcontractorMessage:
    "Aucun sous-traitant n'est actuellement lié à votre entreprise. Pour en établir un, envoyez une invitation.",
  noSubscription: 'Aucun',
  note: 'Remarque',
  noQRCodeDetected: `Aucun code QR détecté pour l'instant. Veuillez déplacer la caméra afin que le code QR soit bien mis au point et entièrement visible dans le champ de vision.`,
  nudgingSpeedDescription:
    'Vitesse de la porte en condition de Nudging. La courbe d’ouverture/fermeture est calculée pour atteindre cette vitesse réglée si le Nudging est actif.',
  nudgingSpeedNote:
    'La vitesse réelle de la porte est également liée aux commutateurs DIP de vitesse des entraînements. Le réglage sur la vitesse 4 signifie 100 % de la valeur 3.',
  nudgingSpeedTitle: 'Vitesse de Nudging',
  obsolete: 'Obsolète',
  offline: 'Hors ligne',
  offlineConnection:
    'Vous êtes actuellement hors ligne, mais vous pouvez utiliser l’outil de programmation.',
  ok: 'Mantenimiento periódico',
  online: 'En ligne',
  openHoldForceDescription: 'Force appliquée en fin d’ouverture.',
  openHoldForceNote:
    'Cette force doit être comprise entre 50 N et 60 N. Sachez qu’une force trop faible entraînera des fermetures et des ouvertures indésirables. Une force de fin d’ouverture trop élevée chauffera inutilement le moteur et pourra le faire fonctionner en mode de réduction.',
  openHoldForceTitle: 'Force de fin d’ouverture (maintien)',
  openSpeedDescription:
    'Vitesse maximale de la porte dans le sens d’ouverture. La courbe d’ouverture est calculée pour atteindre cette vitesse réglée si possible.',
  openSpeedNote:
    'La vitesse réelle de la porte est également liée aux commutateurs DIP de vitesse des entraînements. Le réglage sur la vitesse 4 signifie 100 % de la valeur 1.',
  openSpeedTitle: 'Vitesse d’ouverture',
  otherReason: 'Autre raison',
  overdue: 'En retard',
  parameter: 'Paramètre',
  partInformation: 'Informations sur la Pièce',
  partIsStillOk: 'La pièce est toujours OK',
  partNumber: 'Numéro de Pièce',
  partName: 'Nom de la Pièce',
  password: 'Mot de passe',
  passwordResetSent:
    'Nous vous avons envoyé un courriel contenant des instructions pour réinitialiser votre mot de passe. Veuillez vérifier votre boîte de réception.',
  pendingInvites: 'Invitations en attente',
  pendingUserInvites: 'Invitations utilisateur en attente',
  pendingSubcontractorInvites: 'Invitations de sous-traitant en attente',
  planningAndAlerts: 'Planification et alertes',
  position: 'Position',
  positionOutDescription:
    'En saisissant ce paramètre, le relais de position (lié au FPC) peut être réglé pour commuter à n’importe quelle position de porte. Le réglage standard est 0 (désactivé).',
  positionOutNote:
    'En cas de réglage de ce paramètre sur une autre valeur, le relais commute lorsqu’il atteint la position. Cette fonction n’est presque utilisée que dans les cages avec des cabines pré-mobiles.',
  positionOutTitle: 'Position de sortie',
  postpone: 'Reporter',
  powerReductionFactor: 'Facteur de réduction de puissance',
  predicted: 'Prédit',
  prev: 'Précédent',
  preventiveMaintenance: 'Maintenance préventive',
  preventiveMaintenanceDescription:
    "Remplacez les pièces de rechange de votre porte d'ascenseur avant qu'elles ne se cassent.",
  preventiveMaintenanceUnavailable:
    "Les données saisies sont complètes ! ElevatorSense va maintenant calculer le calendrier d'entretien en fonction de l'utilisation de votre ascenseur. Ce processus peut prendre quelques minutes.",
  priority: 'Priorité',
  product: 'Produit',
  programmingDisabledHint:
    'La programmation de l’électronique est actuellement désactivée par le matériel. Assurez-vous que le commutateur DIP 1 est activé pour permettre la programmation de l’appareil.',
  programmingErrorConnection: (
    <>
      <p>
        We are discovering troubles with the connection to ELEVATORSENSE box.
      </p>
      <p>
        Please make sure to switch off your "mobile data connection" and ensure
        you are connected to the WIFI of the ELEVATORSENSE box only.
      </p>
      <p>
        Afterwards hit the <b>continue</b> button below.
      </p>
    </>
  ),
  programmingErrorNoPopup:
    'Impossible d’ouvrir la fenêtre. Assurez-vous que les fenêtres contextuelles ne sont pas bloquées par votre navigateur.',
  programmingErrorRequest:
    'Demande échouée. Veuillez vous assurer que votre appareil est connecté au réseau WIFI de la boîte ELEVATORSENSE.',
  programmingTool: 'Programmation sans fil',
  programmingToolDescription:
    'Configuration facile de l’électronique de porte.',
  pulley_is_touching_belt: 'Poulie',
  pulley_is_touching_belt_actions:
    'Réglez l’alignement de la courroie de manière à ce que la courroie ne touche pas les bords de la bride de la poulie.',
  pulley_is_touching_belt_description:
    'La bride de la poulie touche la courroie.',
  readyToRunTest: 'Prêt à exécuter l’essai.',
  realtimeMonitoringDescription:
    'Visualisez les données des capteurs et les graphiques de mouvement de porte en temps réel.',
  realtimeMonitoringTitle: 'Diagnostic en temps réel',
  recommended: 'Planifier la vérification du remplacement',
  recommendedActions: 'Actions recommandées',
  recommendedDateOfExchange: 'Date de remplacement recommandée Vérification',
  reload: 'Recharger',
  remove: 'Retirer',
  reopenTimeDescription:
    'Le paramètre de réouverture définit le délai de sécurité après le mouvement de réouverture automatique en position de fin d’ouverture, avant de refermer la porte.',
  reopenTimeNote:
    'Cette fonction est utilisée en cas de procédure de réouverture déclenchée par l’électronique de la porte et non par la commande principale de l’ascenseur.',
  reopenTimeParameter: 'Paramètre de temps de réouverture',
  reopenTimeTitle: 'Temps de réouverture',
  repeatFloor:
    'Veuillez répéter le processus de contrôle de l’installation pour cet étage!',
  repeatWholeRun: (
    <>
      Veuillez répéter le processus de contrôle d’installation <b>complet</b>!
    </>
  ),
  replaced: 'Remplacé',
  replacementWasPostponed: 'Le remplacement a été reporté le :',
  requestNewLink: 'Demander un nouveau lien',
  resetPassword: 'Réinitialiser le mot de passe',
  resetPasswordText: 'Veuillez saisir un nouveau mot de passe',
  revoke: 'Révoquer',
  right: 'Droite',
  rollerBumper: 'Roller Butoir',
  rollerSynchronisationRope: 'Câble de synchronisation du roller',
  runs: 'Courses',
  save: 'Enregistrer',
  scan: 'Balayer',
  scanned: 'Scanné',
  scanAgain: 'Scanner à nouveau',
  searchPlaces: 'Rechercher des lieux',
  select: 'Sélectionner',
  selectCameraMode: 'Sélectionner le mode caméra',
  selectDoorModel: 'Sélectionnez le modèle de porte',
  selectDoorType: 'Sélectionnez le type de porte',
  selectModifiedFloors:
    'Veuillez sélectionner les étages sur lesquels des modifications ont été apportées depuis la dernière course d’essai. Les étages sur lesquels des erreurs se sont produites lors de la dernière course sont présélectionnés.',
  selectPartsHint:
    "Veuillez vérifier quelle pièce est installée et cliquez sur l'image correspondante.",
  send: 'Envoyer',
  sendResults: 'Envoyer les résultats',
  sendTo: 'Envoyez les résultats aux adresses électroniques suivantes:',
  sensorData: 'Données du capteur',
  serviceDriveSwitch: 'Service Drive Switch',
  settings: 'Paramètres',
  share: 'Partager',
  shareBody: (link: string) => `Afficher le résultat de l’essai:\n${link}`,
  shareResults: 'Partager les résultats',
  shareSubject: 'Résultat ELEVATORSENSE',
  shoe: 'Patin',
  showPassedTests: 'Afficher les essais réussis...',
  showTerms: 'Afficher les conditions d’utilisation',
  signUp: 'S’inscrire',
  skid: 'Patin',
  slideingshoe_failure: 'Dysfonctionnement du patin coulissant',
  slideingshoes_usage: 'Utilisation des patins coulissants',
  somethingWentWrong: "Un problème s'est produit. Veuillez réessayer.",
  sortBy: 'Trier par',
  sortByDate: 'Date',
  sortByFloor: "Numéro d'étage",
  sparePartDoesNotFit: 'La pièce de rechange ne convient pas',
  sparePartNotAvailable: 'Pièce de rechange non disponible',
  speed: 'Vitesse',
  start: 'Démarrer',
  startNewTestRun: 'Nouvelle course d’essai',
  started: 'En cours',
  starting_floor: 'Étage de départ',
  status: 'Statut',
  subcontractorNotFound: 'Sous-traitant non trouvé',
  subcontractors: 'Sous-traitants',
  subcontractorManagement: 'Gestion des sous-traitants',
  subscriptionHint:
    'Vous recevrez des notifications par courriel uniquement si vous avez acheté les produits appropriés pour votre boîte ElevatorSense.',
  subscriptionText:
    'Veuillez choisir les notifications par courriel que vous souhaitez recevoir.',
  subscriptions: 'Notifications',
  summarySubscription: 'Rapport quotidien',
  summarySubscriptionExplanation: (
    <>
      Le <b>rapport quotidien</b> comprend un courriel avec un aperçu de tous
      les bâtiments et ascenseurs sélectionnés avec une indication du nombre
      d’erreurs
    </>
  ),
  temperature: 'Température',
  termsOfUse: {
    general: {
      title: 'GENERAL TERMS AND CONDITIONS OF USE OF ELEVATORSENSE APPLICATION',
      sections: [
        {
          title: 'I. Généralités',
          content: [
            <>
              Wittur GmbH (ci-après <strong>"WITTUR"</strong>,{' '}
              <strong>"Nous"</strong>) propose aux utilisateurs (
              <strong>"Utilisateur"</strong>), directement ou par
              l’intermédiaire de ses Affiliées (telles que définies ci-après),
              une solution composée d’un matériel permettant de collecter des
              données provenant d’ascenseurs de passagers et de marchandises
              dans les bâtiments ou leurs composants (la{' '}
              <strong>"ElevatorSense Box"</strong>) ainsi que l’application
              mobile et web ElevatorSense (ci-après{' '}
              <strong>"Mobile App"</strong>, <strong>"Web App"</strong> et
              collectivement <strong>"App"</strong>). (La ElevatorSense Box et
              l’App sont ci-après conjointement désignées{' '}
              <strong>"ElevatorSense Solution"</strong>).
            </>,
            <>
              La fourniture de la ElevatorSense Box est régie par un contrat
              séparé entre l’Utilisateur et WITTUR (
              <strong>"Contrat Box"</strong>).
            </>,
            <>
              L’App peut également être proposée par des Affiliées de WITTUR,
              WITTUR restant la seule partie contractante de l’Utilisateur (tel
              que défini ci-après) dans ce cas, en ce qui concerne la fourniture
              de l’App. <strong>"Affiliée"</strong> désigne toutes les
              entreprises majoritairement détenues ou impliquant une
              participation majoritaire, des sociétés dépendantes et dominantes,
              des sociétés sous la direction commune d’une même société de
              contrôle, ainsi que des sociétés détenant mutuellement des
              participations ou des intérêts en capital.
            </>,
            'WITTUR fournit l’App exclusivement à des entrepreneurs. Un entrepreneur est une personne physique ou morale, ou une société dotée de la capacité juridique, qui, lors de la conclusion d’un acte juridique, agit dans l’exercice de son commerce, de son industrie ou de sa profession indépendante. Les consommateurs ne peuvent pas utiliser l’App.',
            <>
              Les termes suivants régissent entièrement la relation juridique
              entre WITTUR et l’Utilisateur en ce qui concerne l’App (
              <strong>"Conditions d’Utilisation"</strong>) et couvrent{' '}
              <i>entre autres</i> l’accès et l’utilisation de l’App, sous
              réserve de termes supplémentaires ou divergents tels que spécifiés
              dans <a href="#appendix_1">l’Annexe 1</a>. Les Conditions
              d’Utilisation deviennent contraignantes dès l’achèvement de
              l’inscription par l’Utilisateur, et un contrat de service est
              alors conclu avec WITTUR (<strong>"Contrat"</strong>). Les
              Conditions d’Utilisation sont conservées par WITTUR et accessibles
              à tout moment par l’Utilisateur au sein de l’App.
            </>,
            'En tant qu’entrepreneur, l’Utilisateur ne bénéficie d’aucun droit de rétractation.',
          ],
        },
        {
          title: 'II. Portée',
          content: [
            <>
              L’App est une application mobile et web permettant d’accéder aux
              services décrits dans le Contrat de la Box (
              <strong>"Services"</strong>). L’App fournit également des
              informations, des visualisations, des notifications, des
              instructions et des évaluations indiquant les mesures appropriées
              à prendre (<strong>"Recommandations"</strong>). Un lien hypertexte
              vers la boutique en ligne de WITTUR pour les composants de la
              Solution ElevatorSense est mis à la disposition de l’Utilisateur
              dans l’App, en parallèle des Recommandations.
            </>,
            <>
              L’utilisation de l’App est soumise exclusivement aux présentes
              Conditions d’Utilisation ainsi qu’aux informations fournies dans
              l’App. Des conditions divergentes ou contradictoires ne font pas
              partie de l’accord avec WITTUR, à moins que WITTUR ne les ait
              expressément acceptées par écrit. Nonobstant la phrase précédente,
              l’Utilisateur est informé qu’en plus de ces Conditions
              d’Utilisation, il peut être nécessaire de se conformer aux
              conditions d’utilisation supplémentaires de l’opérateur du magasin
              d’applications concerné (par ex. Google pour Google Play Store ou
              Apple pour Apple App Store) si l’Utilisateur utilise la Mobile App
              téléchargée depuis ce magasin, et que WITTUR n’a aucune influence
              sur ces conditions supplémentaires et n’assume donc aucune
              responsabilité à cet égard.
            </>,
            'Le téléchargement de la Mobile App nécessite des dispositifs terminaux appropriés et un accès à Internet, pouvant entraîner des coûts de connexion au Google Play Store / Apple App Store.',
            <>
              Les Parties reconnaissent et conviennent que les Services à
              fournir dans les juridictions locales spécifiées à l’
              <a href="#appendix_1">Annexe 1</a> nécessitent l’ajout de
              conditions ou exigences supplémentaires ou divergentes, venant
              s’ajouter ou se substituer à celles énoncées dans les présentes
              Conditions d’Utilisation. Par conséquent, la relation
              contractuelle entre les Parties est soumise, le cas échéant, à des
              conditions supplémentaires ou divergentes telles que spécifiées à
              l’<a href="#appendix_1">Annexe 1</a> (ci-après :{' '}
              <strong>"Conditions Locales"</strong>). En cas de conflit entre
              les présentes Conditions d’Utilisation et les Conditions Locales,
              ces dernières prévaudront.
            </>,
          ],
        },
        {
          title: 'III. Rémunération',
          content: [
            'L’utilisation des fonctionnalités de base de l’App est gratuite. Toutefois, l’Utilisateur est conscient que l’App ne peut être utilisée qu’avec la ElevatorSense Box, pour laquelle il existe différents modèles d’achat ou de location, soumis au Contrat Box.',
            <>
              Des frais supplémentaires peuvent s’appliquer pour des fonctions
              spéciales disponibles au sein de l’App, que les Utilisateurs
              peuvent acquérir (<strong>"Fonctionnalités"</strong>). Les
              Fonctionnalités accessibles à l’Utilisateur peuvent varier en
              fonction du pays dans lequel l’App est utilisée.
            </>,
          ],
        },
        {
          title: 'IV. Mise à disposition de l’App ; Maintenance',
          content: [
            <>
              WITTUR fournira un accès et une utilisation raisonnables de l’App.
              À cette fin, WITTUR mettra l’App à la disposition des employés
              permanents et/ou indépendants enregistrés de l’Utilisateur (
              <strong>"Personnel Autorisé"</strong>).
            </>,
            'WITTUR peut, à sa seule et entière discrétion, restreindre l’accès à l’App, en tout ou en partie, de manière temporaire ou permanente, en raison de travaux de maintenance, de problèmes de capacité ou d’autres événements indépendants de sa volonté. WITTUR assurera généralement la maintenance de l’App en dehors des heures de bureau normales, sauf si un événement immédiat exige, à la seule et entière discrétion de WITTUR, une maintenance pendant les heures de bureau normales. Toute partie de l’App peut être modifiée sans préavis à tout moment, à la seule et entière discrétion de WITTUR.',
            'WITTUR fournit l’accès à la Web App dans un environnement hébergé, ce qui permet à l’Utilisateur d’utiliser l’App via Internet sans avoir à installer et exploiter le logiciel sur sa propre infrastructure informatique.',
            'WITTUR fera des efforts raisonnables pour maintenir la disponibilité de la Web App à la passerelle entre l’Internet public et le réseau des serveurs d’hébergement de WITTUR, afin que l’Utilisateur puisse l’utiliser. La Web App est considérée comme disponible si elle est utilisable à cette passerelle entre l’Internet public et le réseau de serveurs d’hébergement de WITTUR.',
            <>
              WITTUR fournit des mises à jour de l’App conformément à l’état de
              l’art reconnu, sans rémunération supplémentaire (
              <strong>"Mises à jour"</strong>). Les Mises à jour au sens des
              présentes Conditions d’Utilisation se caractérisent par des
              corrections d’erreurs ou d’autres améliorations mineures, sans
              fonctionnalités nouvelles significatives. Les Mises à jour
              n’incluent pas la livraison ou la mise à disposition de nouvelles
              fonctionnalités ou composants supplémentaires offerts séparément.
            </>,
            <>
              L’Utilisateur doit installer les Mises à jour de la Mobile App
              (c’est-à-dire côté client) dès qu’elles sont disponibles. Les
              Mises à jour permettent à la Mobile App de recevoir des
              informations à jour et d’assurer son bon fonctionnement. Sinon,
              WITTUR ne peut garantir que la Mobile App fonctionnera
              correctement ou que les informations fournies seront à jour. Si
              l’Utilisateur n’installe pas les Mises à jour, mais continue tout
              de même à utiliser la version obsolète de la Mobile App,
              l’Utilisateur renonce ainsi à toute réclamation concernant des
              défauts et à toute demande de dommages-intérêts à laquelle il
              pourrait avoir droit.
            </>,
          ],
        },
        {
          title: 'V. Compte Utilisateur',
          content: [
            <>
              L’accès à l’App ne peut être accordé à l’Utilisateur et à son
              Personnel Autorisé qu’après inscription et création d’un compte
              utilisateur au sein de l’App (
              <strong>"Compte Utilisateur"</strong>).
            </>,
            'L’Utilisateur doit communiquer à WITTUR l’identité de son Personnel Autorisé.',
            'L’Utilisateur est seul et exclusivement responsable de la gestion de son Personnel Autorisé, par exemple l’attribution de rôles et de droits au sein de l’App. En particulier, l’Utilisateur doit mettre fin à l’accès à l’App dès que les personnes concernées ne travaillent plus pour l’Utilisateur.',
            <>
              L’Utilisateur est tenu de conserver secrets les identifiants de
              son Compte Utilisateur et de les protéger contre tout accès par
              des tiers. Si l’Utilisateur prend connaissance de la perte ou de
              l’utilisation abusive de ses identifiants ou soupçonne une
              utilisation abusive de son Compte Utilisateur, il doit en informer
              immédiatement WITTUR et changer son mot de passe en utilisant la
              fonctionnalité prévue à cet effet dans l’App. La divulgation des
              identifiants de connexion à des tiers est interdite. L’Utilisateur
              ne peut pas utiliser les identifiants d’une autre personne pour
              accéder à l’App. L’Utilisateur est seul et exclusivement
              responsable des activités de toute personne accédant à l’App en
              utilisant les identifiants de l’Utilisateur, y compris le
              Personnel Autorisé, même si ces activités n’ont pas été, en
              réalité, autorisées par l’Utilisateur.
            </>,
          ],
        },
        {
          title: 'VI. Obligations de l’Utilisateur',
          content: [
            <>
              L’Utilisateur est seul et exclusivement responsable de tout
              contenu qu’il télécharge, publie ou rend autrement accessible au
              public via l’App. En utilisant un compte sur l’App, l’Utilisateur
              déclare et garantit à WITTUR que les informations qu’il fournit à
              WITTUR (<strong>"Contenu Utilisateur"</strong>) sont vraies,
              exactes et complètes. En outre, l’Utilisateur garantit de
              maintenir le Contenu Utilisateur vrai, exact et complet. WITTUR ne
              contrôle pas le Contenu Utilisateur quant à son exhaustivité,
              exactitude, légalité, disponibilité, qualité ou adéquation à un
              usage particulier.
            </>,
            <>
              Les Services ne peuvent être effectués que tant que la
              ElevatorSense Box est connectée à Internet. L’Utilisateur
              reconnaît qu’il est de sa seule et exclusive responsabilité
              d’assurer la mise en œuvre technique de la ElevatorSense Box. Par
              la présente, l’Utilisateur renonce à tout droit et à toute
              réclamation pouvant résulter d’une déconnexion de la ElevatorSense
              Box.
            </>,
            'Afin d’exécuter les Services, l’Utilisateur est tenu de suivre strictement les instructions d’installation et d’exploitation fournies dans l’App.',
            <>
              Si WITTUR fournit des mises à jour et/ou des améliorations
              téléchargeables pour la ElevatorSense Box, l’Utilisateur est tenu
              de les installer afin d’assurer une interaction correcte entre
              l’App et la ElevatorSense Box. Dans le cas contraire, WITTUR ne
              peut garantir la bonne exécution des Services. Si l’Utilisateur
              n’installe pas une mise à jour et/ou des améliorations fournies,
              mais continue néanmoins à utiliser la version obsolète de la
              ElevatorSense Box, l’Utilisateur renonce ainsi à toute réclamation
              concernant des défauts et à toute demande de dommages-intérêts à
              laquelle il pourrait avoir droit.
            </>,
            'Il est interdit à l’Utilisateur de mener des activités sur ou en lien avec l’App qui enfreignent la législation applicable ou portent atteinte aux droits de tiers.',
            {
              text: 'En outre, il est interdit à l’Utilisateur d’effectuer les activités suivantes, indépendamment de toute violation de la loi :',
              list: [
                'la diffusion de virus, de chevaux de Troie et d’autres fichiers nuisibles ;',
                'l’envoi de courriels indésirables, spam ou chaînes de lettres ;',
                'la diffusion de contenu ou de communication offensant, à caractère sexuel, obscène ou diffamatoire, ainsi que tout contenu ou communication susceptible de promouvoir ou de soutenir le racisme, le fanatisme, la haine, la violence physique ou des actes illégaux (explicites ou implicites) ;',
                'la demande à d’autres utilisateurs de divulguer des mots de passe ou des données personnelles à des fins commerciales ou illégales ;',
                'la diffusion et/ou la reproduction publique de contenu disponible sur l’App.',
              ],
            },
            'WITTUR se réserve le droit de suspendre l’Utilisateur de l’utilisation de l’App à tout moment, si ces Conditions d’Utilisation, la législation applicable ou les droits de tiers sont enfreints, ou si WITTUR a des raisons raisonnables de croire qu’ils pourraient l’être.',
          ],
        },
        {
          title: 'VII. Droits de Propriété Intellectuelle',
          content: [
            <>
              WITTUR accorde à l’Utilisateur un droit révocable, non exclusif,
              non transférable, non sous-licenciable et limité dans le temps à
              la durée de l’Accord, d’utiliser l’App conformément aux présentes
              Conditions d’Utilisation (<strong>"Licence"</strong>). Le
              détenteur des droits de propriété intellectuelle sur l’App est
              WITTUR, ou ses partenaires commerciaux ou d’autres tiers qui ont
              mis le contenu et les droits de propriété intellectuelle
              correspondants à la disposition de WITTUR sous licence. L’App et
              le contenu mis à disposition sur l’App ne peuvent donc pas être
              modifiés, étendus, édités, copiés et/ou distribués d’une autre
              manière par l’Utilisateur.
            </>,
            <>
              Les recommandations générées par l’App peuvent être utilisées par
              l’Utilisateur pour soutenir ses propres services envers ses
              clients. WITTUR n’assumera aucune responsabilité ni obligation
              quant à ces services, ni quant aux actions de l’Utilisateur ou de
              ses clients qui en découlent.
            </>,
            <>
              Sauf disposition contraire dans les présentes Conditions
              d’Utilisation, l’Utilisateur s’engage à ne pas reproduire,
              distribuer, modifier ou créer des œuvres dérivées de l’App ou de
              ses composants, et à ne pas procéder à de l’ingénierie inverse ou
              à la décompilation de l’App, sauf si cela est autorisé par une loi
              impérative.
            </>,
            <>
              L’Utilisateur reconnaît et accepte que WITTUR soit autorisée à
              utiliser les données traitées via l’App sous forme anonymisée (au
              sens des lois applicables en matière de protection des données) à
              des fins commerciales de WITTUR, notamment à les reproduire et à
              accorder l’accès à ces données à des tiers. WITTUR ne
              dé-anonymisera pas les données et est tenue de prendre toutes les
              mesures appropriées pour empêcher toute dé-anonymisation. Si
              l’accès aux données est accordé à des tiers, WITTUR doit les
              obliger à ne pas dé-anonymiser les données et à prendre toutes les
              mesures appropriées pour empêcher toute dé-anonymisation. Dans la
              mesure requise, l’Utilisateur accordera à WITTUR une licence
              irrévocable, mondiale, non exclusive, libre de redevances et
              sous-licenciable pour utiliser ces données. Les finalités
              commerciales incluront, sans s’y limiter, le développement, la
              fabrication, l’amélioration et/ou la commercialisation de produits
              et de services.
            </>,
          ],
        },
        {
          title: 'VIII. Durée et Résiliation',
          content: [
            <>
              Le Contrat est conclu pour une durée illimitée et l’Utilisateur
              est en droit de le résilier à tout moment pour convenance.
              L’Utilisateur a reconnu et est informé (i) que la fonctionnalité
              de l’App sera limitée ou suspendue à compter du moment où la durée
              d’abonnement et/ou les droits d’utilisation de la ElevatorSense
              Box au titre du Contrat Box expirent, et (ii) que la résiliation
              du Contrat par l’Utilisateur pour convenance n’affectera pas les
              obligations pouvant encore découler du Contrat Box.
            </>,
            <>
              Sauf accord contraire avec l’Utilisateur, les licences pour les
              Fonctionnalités achetées par l’Utilisateur sont valables jusqu’à
              la fin de la durée respective de ladite Fonctionnalité. Si
              l’Utilisateur ne résilie pas la licence d’une Fonctionnalité trois
              (3) mois avant la fin de la durée, celle-ci sera automatiquement
              prolongée de douze (12) mois, et l’Utilisateur sera tenu de payer
              les frais supplémentaires convenus pour cette période. Le droit de
              résilier une licence d’une Fonctionnalité avec effet immédiat pour
              motif légitime reste inchangé. L’article VIII.1 s’applique mutatis
              mutandis aux Fonctionnalités.
            </>,
            <>
              À la résiliation du Contrat, l’Utilisateur doit (i) cesser
              immédiatement d’utiliser l’App et reconnaît que WITTUR peut
              bloquer l’accès de l’Utilisateur et de son Personnel Autorisé à
              l’App, et (ii) immédiatement, au plus tard dans les 5 (cinq)
              jours, supprimer et détruire tout contenu téléchargé et tout
              matériel imprimé. L’Utilisateur ne détient aucun droit, titre ou
              intérêt (et aucun droit d’auteur, marque ou autre droit de
              propriété intellectuelle) sur l’App ou tout contenu, information,
              matériel, application ou autre fonctionnalité s’y rapportant.
            </>,
          ],
        },
        {
          title: 'IX. Droits en Cas de Défauts',
          content: [
            <>
              L’App est fournie et maintenue dans un état approprié à une
              utilisation contractuelle. L’obligation de maintenance n’inclut
              pas l’adaptation des fonctionnalités de l’App elle-même à des
              conditions d’exploitation modifiées et à des évolutions techniques
              et fonctionnelles qui ne sont pas liées à la ElevatorSense Box,
              l’adaptation à l’étendue des fonctionnalités de produits
              concurrents ou la création de compatibilité avec de nouvelles
              versions de navigateurs.
            </>,
            {
              text: (
                <>
                  WITTUR garantit que l’App est exempte de droits de tiers, en
                  particulier de droits de propriété intellectuelle, qui
                  restreindraient ou excluraient son utilisation conformément
                  aux présentes Conditions d’Utilisation. En cas d’allégation
                  selon laquelle l’utilisation de l’App, telle qu’autorisée par
                  les présentes Conditions d’Utilisation, viole ou enfreint un
                  brevet, un droit d’auteur, une marque ou tout autre droit de
                  propriété intellectuelle d’un tiers (
                  <strong>"Violation"</strong>)
                </>
              ),
              list: [
                <>
                  WITTUR a le droit soit de modifier l’App pour que la Violation
                  ne s’applique plus, soit d’obtenir une autorisation permettant
                  l’utilisation de l’App conformément aux Conditions
                  d’Utilisation, sans limitation et sans frais supplémentaires
                  pour l’Utilisateur ;
                </>,
                <>
                  l’Utilisateur s’engage à fournir à WITTUR une notification
                  rapide par écrit (par ex. par e-mail) et toutes les
                  informations relatives à la Violation ; et
                </>,
                <>
                  l’Utilisateur coopérera avec WITTUR et lui apportera son
                  assistance pour mettre fin à la Violation, dans la mesure où
                  on peut raisonnablement l’attendre de lui.
                </>,
              ],
            },
            'Le droit de réclamer des dommages-intérêts est soumis aux limitations de responsabilité prévues à l’article X. ci-après.',
          ],
        },
        {
          title: 'X. Limitation de Responsabilité',
          content: [
            'WITTUR est responsable sans limitation des dommages causés par une intention délibérée ou une négligence grave de WITTUR ou de ses auxiliaires, des dommages causés en cas de préjudice corporel, d’atteinte à la vie ou à la santé, ainsi que dans le cadre des lois applicables en matière de responsabilité du fait des produits.',
            <>
              En cas de dommages et de dépenses causés par une simple
              négligence, WITTUR ne sera responsable que des dommages
              typiquement prévisibles si ces dommages et dépenses résultent de
              la violation par WITTUR d’obligations contractuelles essentielles.
              Les obligations contractuelles sont essentielles si leur exécution
              est nécessaire à la réalisation de l’objet du Contrat et si
              l’Utilisateur peut donc compter sur leur exécution.
            </>,
            'Pour le reste, WITTUR n’assume aucune responsabilité.',
            <>
              Pour éviter toute ambiguïté, WITTUR ne pourra en aucun cas être
              tenue responsable d’un dommage ou d’une perte si, et dans la
              mesure où, ce dommage ou cette perte résulte du non-respect par
              l’Utilisateur des recommandations ou avis fournis par ou contenus
              dans l’App.
            </>,
          ],
        },
        {
          title:
            'XI. Limitations de Responsabilité concernant le Contenu Fournis',
          content: [
            <>
              Il n’existe aucune responsabilité pour toute information fournie
              dans les Services et toute Recommandation fournie dans l’App (
              <strong>"Contenu Fourni"</strong>) par WITTUR. Comme il ressort de
              l’article VII.2, WITTUR n’assume aucune responsabilité en ce qui
              concerne l’utilisation du Contenu Fourni par ou pour les propres
              clients de l’Utilisateur.
            </>,
            <>
              Lors de la fourniture d’informations dans les Services, ces
              informations peuvent ne pas être exactes, car les données générées
              par la ElevatorSense Box sont soumises à des conditions
              extérieures.
            </>,
            <>
              Lors de la fourniture de Recommandations, l’App fournit certaines
              probabilités pour un résultat particulier et recommande des
              actions en conséquence. Cependant, l’App ne fournit pas
              d’évaluation définitive. C’est à l’Utilisateur qu’il incombe
              exclusivement de procéder à une évaluation appropriée, y compris
              l’achat de produits ElevatorSense dans la boutique en ligne de
              WITTUR en guise de remplacement.
            </>,
            {
              text: 'L’Utilisateur doit tenir compte des limitations suivantes qui s’appliquent au Contenu Fourni généré par cette App :',
              list: [
                <>
                  Le Contenu Fourni est généré à l’aide d’algorithmes très
                  complexes. Cependant, il est évidemment impossible pour un
                  algorithme de modéliser chaque caractéristique d’un événement
                  individuel. L’App fournit donc une approximation
                  continuellement améliorée pour soutenir une prise de décision
                  personnalisée.
                </>,
                <>
                  De nouvelles données et des données mises à jour, qui
                  constituent la base du Contenu Fourni, sont constamment
                  introduites dans l’algorithme. WITTUR compile ces données au
                  mieux de ses connaissances avec la diligence professionnelle
                  requise. Toutefois, aucune garantie ne peut être donnée quant
                  à l’exactitude, l’exhaustivité et la mise à jour des données.
                  Des erreurs dans un jeu de données pourraient conduire à un
                  Contenu Fourni peu fiable.
                </>,
              ],
            },
          ],
        },
        {
          title: 'XII. Protection des Données',
          content: [
            <>
              Étant donné que la protection des données est une priorité chez
              WITTUR, WITTUR se conforme strictement aux lois applicables en
              matière de protection des données lors du traitement (par ex.
              collecte, utilisation, divulgation, etc.) de données personnelles.
              Sauf indication contraire concernant le traitement spécifique des
              données personnelles par WITTUR, les informations relatives à ce
              traitement sont incluses dans la politique de confidentialité
              applicable, accessible dans l’App.
            </>,
            'L’Utilisateur doit respecter les lois applicables en matière de protection des données lorsqu’il fournit des données personnelles à WITTUR, par exemple via l’App.',
          ],
        },
        {
          title: 'XIII. Modifications / Transfert du Contrat',
          content: [
            <>
              WITTUR est en droit de modifier les présentes Conditions
              d’Utilisation à tout moment. L’Utilisateur sera informé de ces
              modifications au moins soixante (60) jours calendaires avant la
              mise en œuvre prévue des modifications. Si l’Utilisateur ne s’y
              oppose pas dans un délai de trente (30) jours calendaires à
              compter de la réception de la notification et continue à utiliser
              l’App après l’expiration de ce délai, les modifications seront
              réputées validement acceptées à compter de l’expiration de ce
              délai d’opposition. Dans cette notification, l’Utilisateur sera
              informé de son droit d’opposition et de ses conséquences.
            </>,
            <>
              WITTUR se réserve le droit de transférer le présent Contrat avec
              l’Utilisateur à une autre entreprise. Les Utilisateurs seront
              informés de ce transfert et pourront se retirer du Contrat dans un
              délai de dix (10) jours calendaires après avoir reçu l’information
              relative au transfert.
            </>,
          ],
        },
        {
          title: 'XIV. Dispositions Diverses',
          content: [
            <>
              Si une ou plusieurs dispositions des présentes Conditions
              d’Utilisation sont invalides ou inapplicables en raison d’une
              violation de la loi applicable ou pour toute autre raison, les
              autres dispositions demeurent valables. La disposition invalide ou
              inapplicable sera remplacée par une disposition valide et
              applicable, reflétant le plus fidèlement possible l’intention
              mutuelle des parties.
            </>,
            <>
              La loi régissant la validité, l’interprétation et la mise en œuvre
              des présentes Conditions d’Utilisation est le droit allemand, à
              l’exclusion de ses règles sur les conflits de lois, et les
              tribunaux compétents pour connaître de tout litige découlant ou
              relatif aux présentes Conditions d’Utilisation sont les tribunaux
              de Munich.
            </>,
          ],
        },
        {
          title: 'XV. Utilisateurs Internationaux',
          content: [
            <>
              Cette App est exploitée, surveillée et mise à jour par WITTUR
              GmbH. Elle est uniquement destinée à une utilisation dans les pays
              listés à<a href="#appendix_1">l’Annexe 1</a>. Si l’Utilisateur
              accède à cette App et/ou télécharge du contenu depuis l’extérieur
              de ces pays, l’Utilisateur est seul et exclusivement responsable
              de s’assurer que cette utilisation est conforme à la législation
              locale applicable.
            </>,
          ],
        },
      ],
    },
    appendix: {
      title:
        'Annexe 1 : PAYS DE DISTRIBUTION ET CONDITIONS DE JURIDICTION LOCALE',
      content: [
        {
          heading:
            'Conditions Additionnelles ou Divergentes pour le Pays de Distribution',
          details: [
            {
              country: 'Inde',
              content: [
                'La Section V. (Compte Utilisateur) est complétée comme suit :',
                <>
                  L’Utilisateur consent à ce que WITTUR ou ses Affiliées
                  collectent son mot de passe afin de permettre l’accès et
                  l’utilisation de l’App. Le mot de passe peut être traité par
                  WITTUR ou ses Affiliées conformément à la Politique de
                  Confidentialité.
                </>,
                <>
                  La Section X.2. (Limitation de Responsabilité) est modifiée
                  comme suit : Pour les dommages et dépenses causés par un
                  manquement matériel de WITTUR, WITTUR ne sera responsable que
                  des dommages qui surviennent naturellement dans le cours
                  normal de ce manquement, ou dont l’Utilisateur savait, lors de
                  l’acceptation des Conditions d’Utilisation, qu’ils étaient
                  susceptibles de résulter de ce manquement. (i) Cela n’inclut
                  pas les dommages découlant de pertes ou dommages éloignés et
                  indirects causés par le manquement matériel et (ii) la
                  responsabilité est limitée à la contrepartie reçue de
                  l’Utilisateur pour la ElevatorSense Solution, montant que
                  l’Utilisateur et WITTUR conviennent être une estimation
                  préalable juste, raisonnable et authentique de tout dommage
                  résultant d’un manquement matériel.
                </>,
              ],
            },
            {
              country: 'Royaume-Uni',
              content: [
                <>
                  La Section X.2. (Limitation de Responsabilité) est modifiée
                  comme suit :
                  <br /> WITTUR n’est responsable que des dommages et dépenses
                  typiquement prévisibles si ces dommages et dépenses résultent
                  d’un manquement matériel de WITTUR, que WITTUR n’a pas corrigé
                  dans les trente (30) jours suivant la réception de la
                  notification de ce manquement par le Client. Sous réserve de
                  ce qui précède, WITTUR exclut toute autre responsabilité
                  (qu’elle soit directe ou indirecte, et quelle qu’en soit la
                  cause) au titre des présentes Conditions d’Utilisation.
                </>,
              ],
            },
          ],
        },
      ],
    },
  },
  testCompleted: 'Essai terminé.',
  testResults: 'Résultats de l’essai',
  time: 'Heure',
  tooHigh: 'Trop haut',
  tooLoose: 'Trop lâche',
  tooLow: 'Trop bas',
  tooTight: 'Trop serré',
  top: 'haut',
  topToBottom: 'De haut en bas',
  touching: 'en contact',
  transformer: 'Transformateur',
  truckRoller: 'Chariot Roller',
  truckRollerInferior: 'Galet inférieur chariot',
  truckRollerSuperior: 'Galet superior chariot',
  twisted_cd_panels: 'Panneaux CD torsadés',
  twisted_ld_panels: 'Panneaux torsadés',
  unlinkBox: 'Dissocier la boîte',
  unreadNotifications: (n: number) =>
    `${n} notification non lue${n === 1 ? '' : 's'}`,
  unshare: 'Annuler le partage',
  update: 'Mise à jour',
  updateRequired: 'Mise à jour requise',
  updateStaticData: 'Mettre à jour les données',
  updating: 'Mise à jour...',
  upToDate: 'Mise à jour',
  usage: 'Utilisation',
  usedElectronics: 'Ascenseurs utilisés',
  validFrom: 'Valable à partir de',
  validUntil: 'Valable jusqu’à',
  vibration: 'Vibration',
  viewErrorLog: 'Afficher le journal des erreurs',
  waitWhileInitializingTest:
    'Veuillez patienter pendant l’initialisation de l’essai...',
  waitingForData: 'En attente de l’arrivée de nouvelles données',
  waitingForTestResults: 'En attente des résultats de l’essai...',
  warning: 'Avertissement',
  warnings: 'Avertissements',
  warrantyExtensionRequestedOn: (date: string) =>
    `L'extension de garantie a été demandée le ${date}`,
  whyNotReplaced: "Pourquoi le composant n'a- t - il pas été remplacé?",
  wifiScanned: 'Le WIFI a été scanné',
  years: 'années',
  yes: 'Oui',
  zero_position: 'Position zéro',
  zero_position_description: 'La position zéro n’est pas correcte.',
  zero_position_eco_actions:
    '`Procédure ECO+ : 1) Réglez S1DIP1 sur ON (mode Service). 2) Fermez la porte à la main (position zéro). 3) Appuyez sur le bouton d’apprentissage pendant >10 s (Longlearn). 4) Ouvrez et fermez la porte à l’aide du bouton d’entraînement de service. 5) Maintenez enfoncés les boutons à l’extrémité de la porte ouverte et à l’extrémité de la porte fermée pendant >2 s. Résultat : Le voyant d’état doit s’éteindre après 2 cycles.`',
  zero_position_midi_actions:
    ' `Procédure MIDI/SUPRAV2 : Fermez la porte à la main (position zéro). Suivez les instructions du pictogramme.`',
  yourBuilding: 'Votre bâtiment',
  yourBuildings: 'Vos bâtiments',
};
